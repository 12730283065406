import React, { useState, useEffect } from "react";
import Button from "../../Assets/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import { Checkbox } from "@material-ui/core";
import ValidateTaxId from "./ValidateTaxId";
import TidFailure from "./TidFailure";
import { SICs } from "../../Assets/SICs";
import { CSVReader } from "react-papaparse";
import uuidv4 from "uuid/v4";
import { FirebaseDB, FirebaseContext } from "react-ugp-firebase";
import states from "../../../utils/States";
import { RaterContext } from "../../RaterV3";
import axios from "axios";
import dateformat from "dateformat";
import { CSVLink } from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import Collapse from "@material-ui/core/Collapse";
import CheckIcon from "@material-ui/icons/Check";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import Divider from "@material-ui/core/Divider";
import { CustomTextField } from "./CustomTextField";
import { search, OptionsSelect } from "../../../utils/AsyncSelect";
import date from "date-and-time";
import moment from "moment";

import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const isDev = process.env.NODE_ENV === "development";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  prediction: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#5482D2",
      cursor: "pointer",
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function RFP() {
  const classes = useStyles();
  const [name, setName] = useState({ value: "", error: false });
  const [addressLine1, setAddressLine1] = useState({ value: "", error: false });
  const [addressLine2, setAddressLine2] = useState({ value: "", error: false });
  const [zip, setZip] = useState({ value: "", error: false });
  const [tid, setTid] = useState("");
  const [isBroker, setIsBroker] = useState("");
  const [addFiles, setAddFiles] = useState(false);
  const [groupDate, setGroupDate] = useState("");
  const [sic, setSic] = useState("");
  const [notes, setNotes] = React.useState("");
  const [validateTaxId, setValidateTaxId] = useState(false);
  const [brokerName, setBrokerName] = useState("");
  const [brokerLast, setBrokerLast] = useState("");
  const [brokerEmail, setBrokerEmail] = useState("");
  const [broker, setBroker] = useState(undefined);
  const [city, setCity] = useState("");
  const [predictions, setPredictions] = React.useState([]);
  const [censusFile, setCensusFile] = useState({ file: "", name: "" });
  const [notifySalesSupportToComplete, setNotify] = React.useState(false);
  const [addFile1, setAddFile1] = useState({ file: "", name: "" });
  const [addFile2, setAddFile2] = useState({ file: "", name: "" });
  const [addFile3, setAddFile3] = useState({ file: "", name: "" });
  const [addFile4, setAddFile4] = useState({ file: "", name: "" });
  const [censusParsed, setCensusParsed] = useState(false);
  const [censusOption, setCensusOption] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [tidFail, setTidFail] = useState(false);
  const [brokerOptions, setBrokerOptions] = React.useState([]);
  const { user, upload, restConfig } = React.useContext(FirebaseContext);

  const [userDocuments, setUserDocuments] = useState([]);

  // const [userCRMid, setUserCRMid] = useState("");

  const sessionToken = React.useMemo(() => {
    return uuidv4();
  }, []);

  const {
    error,
    setLoading,
    setOpportunityId,
    setCensus,
    setZipCode,
    census,
    setSicCode,
    setGroupEffectiveDate,
    setError,
    setSuccess,
    setMessage,
    setRestricted,
    setHasOpportunity,
    validateCensus,
    state,
    setState,
    setGroupName,
    setValue,
    setAdminFees,
    webError,
    requiredLevel,
    setSelectedBroker,
    selectedBroker,
    discounts,
    setDiscounts,
    userCRMid,
    setUserCRMid,
    canabisdialogue,
    setCanabisDialogue,
    canabisChecker,
    setCanabisChecker,
  } = React.useContext(RaterContext);

  const buttonRef = React.createRef();

  const censusOptions = ["Upload census in Optimed format", "Upload census in any format"];

  const googleApiKey = "AIzaSyB-w22phRR09F-SESymn1uQT4Nl4n8SjVM";

  //Variable that only recalculates when user changes and no other time using useMemo.
  let isEmployee = React.useMemo(
    (x) => {
      return user && user.roles && user.roles.indexOf("broker") === -1;
    },
    [user]
  );

  const genDates = () =>
    _.map(_.range(13), (index) => {
      const formatPattern = "MM/01/YYYY";
      return date.format(
        date.addMonths(date.parse(date.format(new Date(), formatPattern), formatPattern), index),
        formatPattern
      );
    });

  //Input a string and regex and returns a boolean to run logic on
  function hasSpecialChars(string, regex) {
    return regex.test(string);
  }

  /*NOTE:
    Handles file upload in order to limit the file size for any file to 3 MB. Takes in a setFile paramater as an argument
    which is a function that is a setter to the corresponding file that is being set.
     */
  const fileUpload = (evt, setFile) => {
    if (evt.target) {
      let file = evt.target.files[0];
      if (file && file.size / 1024 / 1024 > 3) {
        alert("The selected file is too big, please select a different file that's 3MB or less");
      } else {
        setFile({ file: file, name: evt.target.value ? evt.target.value : "" });
      }
    } else {
      setFile({ file: evt, name: evt.name ? evt.name : "" });
    }
  };

  var db = FirebaseDB;

  useEffect(() => {
    if (user) {
      console.log("testing in useeffect");
      console.log(user.uid);
      FirebaseDB.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Document data:", doc.data());
            setUserDocuments(doc.data());
            setUserCRMid(doc.data().crmId);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((err) => {
          console.log("Error getting document", err);
          // setError(true);
          // setMessage(
          //   "Unable to retrieve the product from the database. Please try again later, if the problem persists, contact support."
          // );
        });
    }
  }, [db, user]);

  // useEffect(() => {
  //   if (user) {
  //     console.log("testing in useeffect");
  //     console.log(user.uid);
  //     const query = db.collection("users").where("userId", "==", user.uid);
  //     // const query = doc(db, "user-info", uid);
  //     query
  //       .get()
  //       .then((querySnapshot) => {
  //         const documents = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  //         setUserDocuments(documents);
  //       })
  //       .catch((error) => {
  //         console.error("Error getting documents: ", error);
  //       });
  //   }
  // }, [db, user]);
  React.useEffect(() => {
    console.log("testing in discounts for internal");
    const getDiscounts = async () => {
      console.log("trigger discounts in rfp");
      const brokerRef = FirebaseDB.collection("discounts").doc(userCRMid);
      await brokerRef
        .get()
        .then((doc) => {
          setDiscounts({ ...discounts, ...doc.data().discounts });
        })
        .catch((err) => {
          console.log("No discount found.", err);
        });
    };

    if (userCRMid !== "" && user.roles && user.roles.includes("broker")) {
      getDiscounts();
    }
  }, [userCRMid]);

  console.log("this is document");

  console.log(userDocuments);

  console.log("THIS IS DB");
  console.log(db);

  console.log("this is user CRM ID");
  console.log(userCRMid);

  /*NOTE:
    Checks if everything in the form is 'valid' in order to enable/disable the submit button accordingly. The regex expression is run against
    the email field of the broker if the user isn't the broker on the quote. This regex now accepts special characters throughout the email.
     */
  const isValid = () => {
    // console.log(zip.value)

    if (
      name.value === "" ||
      name.error === true ||
      addressLine1.value === "" ||
      addressLine1.error === true ||
      addressLine2.error === true ||
      hasSpecialChars(city, /[~`!#$%\^&*+=[\]\\';,/{}|\\":<>\?]|[0-9]/g) ||
      zip.value === undefined ||
      zip.value === "" ||
      zip.error === true ||
      zip.value.length !== 5 ||
      groupDate === ""
    ) {
      return false;
    } else if (
      requiredLevel > 1 &&
      ((!censusParsed && censusOption.label === "Upload census in Optimed format") ||
        (censusOption.label === "Upload census in any format" && censusFile.file === "") ||
        !censusFile.file ||
        censusOption === "")
    ) {
      return false;
    } else if (isEmployee && (!notifySalesSupportToComplete || (brokerName === "" && !broker))) return false;
    else if (sic === "") return false;
    else if (!isEmployee && isBroker === "") return false;
    else if (!isEmployee && isBroker.label === "No") {
      if (brokerName === "" || brokerLast === "") {
        return false;
      }
      let regex = RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+(\.[a-zA-Z0-9]{2,30})+$/);
      if (regex.test(brokerEmail) !== true) {
        return false;
      }
    }
    return true;
  };

  /*NOTE:
    If an employee user sets a broker value with the select dropdown, the brokerName field is updated
    to correspond with the value that was selected.
     */
  React.useEffect(() => {
    console.log("this is sic checking useeffect");
    console.log(sic);

    console.log(typeof sic.value);

    const canabis = [5993, 5912, 5122, 5199, 8099, 9999, 132, 139, 5999];

    if (canabis.includes(sic.value) && sic.value !== "" && sic.value !== undefined) {
      setCanabisDialogue(true);
    }
  }, [sic]);

  function handleChange(event) {
    console.log(event);

    console.log(event.target.checked);

    setCanabisChecker(event.target.checked);

    console.log("this is canabis checker");
  }

  React.useEffect(() => {
    if (broker && broker.value && broker.value.brokerName) {
      setBrokerName(broker.value.brokerName);
    }
  }, [broker]);

  /*NOTE:
    This useEffect gets all the address predictions from google anytime the first address line a user is entering changes.
    It will only start hitting the google maps API once the user has entered in at least 3 characters so we can limit the
    amount of calls made by each user.
     */
  useEffect(() => {
    getPredictionsFromGoogle();
  }, [addressLine1]);

  function arrayToCSV(objArray) {
    const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    return array.reduce((str, next) => {
      str +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      return str;
    }, str);
  }

  const getPredictionsFromGoogle = () => {
    if (addressLine1.value && addressLine1.value.length > 2 && !addressLine1.complete) {
      let payload = {
        key: googleApiKey,
        input: addressLine1.value,
        sessiontoken: sessionToken,
      };
      axios({
        method: "get",
        url: isDev
          ? //adding the heroku call is fine inside dev. Easy way to bypass CORS for testing.
            "https://maps.googleapis.com/maps/api/place/autocomplete/json"
          : "/rating/autocomplete",
        params: payload,
      })
        .then((res) => {
          // console.log(res)

          if (res.data && res.data.predictions && res.data.predictions.length > 0) {
            setPredictions(res.data.predictions);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  /*NOTE:
    Once a user clicks on one of the address prediction options, this function runs. This function uses the
    place_id attached to the prediction to his the google place details API. Based on the data returned,
    that data is parsed and fills out all address related fields in the remainder of the form.
     */
  const selectPrediction = (e, prediction) => {
    e.preventDefault();
    setLoading(true);
    let params = {
      key: googleApiKey,
      place_id: prediction.place_id,
    };
    axios({
      method: "get",
      //adding the heroku call is fine inside dev. Easy way to bypass CORS for testing.
      url: isDev ? "https://maps.googleapis.com/maps/api/place/details/json" : "/rating/details",
      params,
    })
      .then((res) => {
        // console.log(res)

        let formattedAddress = res.data.result.formatted_address.split(",");
        let address = formattedAddress[0];
        let city = formattedAddress[1].replace(" ", "");
        let state = formattedAddress[2].replace(" ", "").split(" ")[0];
        let zipCode = formattedAddress[2].replace(" ", "").split(" ")[1];
        setAddressLine1({ value: address, error: false, complete: true });
        setZip({ value: zipCode, error: false });
        setState({ value: state, label: state });
        setCity(city);
        setPredictions([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  /*NOTE:
    Function that is called after form is submitted which handles uploading every file that the user uploaded to firebase
     */
  const uploadAllFiles = () => {
    let promises = [];
    let metadata = {
      contentType:
        censusFile.file && censusFile.file.type && censusFile.file.type !== "application/vmd.ms-excel"
          ? censusFile.file.type
          : "text/csv",
    };
    censusFile.file ? promises.push(upload(censusFile.file, undefined, undefined, metadata)) : console.log("No file");
    const additionalFiles = [addFile1, addFile2, addFile3, addFile4];
    additionalFiles.forEach((file) => {
      if (file.file !== "") {
        promises.push(upload(file.file));
      }
    });
    const promise = new Promise((resolve, reject) => {
      Promise.all(promises)
        .then((files) => {
          resolve(files);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  };

  React.useEffect(() => {}, [censusFile]);

  /*Parses the data off of the csv file into our full census.*/
  const handleReadCSV = (data) => {
    let employees = data.map((row) => {
      return row.data;
    });

    const dobRanges = employees.map((employeeData) => {
      const dob = moment(employeeData.dob, "MM/DD/YYYY");
      const now = moment();

      const difference = now.diff(dob, "days");

      return difference;
    });

    //3652 === 10 years (Including leap years) from now() function.
    const dobCheck = (arr) => arr.filter((x) => x < 3652).length;

    // console.log(dobCheck(dobRanges));

    if (dobCheck(dobRanges) === 0) {
      //Function that makes all the keys lower case so that the file columns are not case sensitive.
      employees = _.map(employees, (x) => {
        return _.mapKeys(x, (value, key) => {
          return key.toLowerCase();
        });
      });
      employees = employees.map((employee) => {
        let dob = dateformat(employee.dob, "mm/dd/yyyy", true);
        let gender = employee.gender ? employee.gender.charAt(0) : undefined;
        return {
          name: employee.name,
          dob,
          coverage: employee.coverage,
          gender,
        };
      });

      //Filter function to make sure that every employee object has a defined value for all 4 required fields.
      employees = _.filter(employees, (x) => x.name && x.dob && x.coverage && x.gender);
      validateCensus(employees)
        .then((res) => {
          setLoading(true);
          setTimeout(() => {
            setCensusParsed(true);
            setCensus(employees);
            let csvArray = arrayToCSV(employees);
            // console.log(csvArray)
            let blob = new Blob([csvArray], { type: "text/csv" });
            setCensusFile({
              file: new File([blob], `${name.value}Census${sessionToken}.csv`, {
                type: "text/csv",
              }),
              name: `${name.value}Census${sessionToken}.csv`,
            });
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          setLoading(false);
          console.log(buttonRef);
          setCensusParsed(false);
          setMessage(
            typeof err === "string"
              ? err
              : "Invalid census format. You must either fill out census manually or upload a file with the proper format."
          );
        });
    } else {
      setError(true);
      setLoading(false);
      setCensusParsed(false);
      setMessage("Your census contains an ineligible DOB for a member. Eligible members must be 10 years or older.");
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const openDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  /*NOTE:
    Saves the opportunity to Firebase with all the relevant information. This happens only after a 200 is received from the
     */
  const saveOppToFirebase = (oppId, props) => {
    let obj = {
      isActive: true,
      name: name.value,
      zipCode: zip.value,
      sicCode: sic.value,
      census: census ? census : [],
      state: state.value,
      groupEffectiveDate: groupDate.value,
      allowedUsers: [user.uid],
      notes: brokerName + "\n" + notes,
      ...props,
    };
    if (notifySalesSupportToComplete) obj.notifySalesSupportToComplete = notifySalesSupportToComplete.value === "Yes";
    if (broker && broker.value) obj.broker = broker.value;
    return FirebaseDB.collection("opportunities").doc(oppId).set(obj);
  };

  /*NOTE:
    Handles the submit. First uploads all the files then creates a corresponding payload and sends the payload to endpoint.
     Once a 200 response is received without the isError flag being true, the user is automatically moved on to step 4.
     */

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (isDev) {
      //replicating this code in both logic branches.
      axios({
        method: "get",
        url: `https://maps.googleapis.com/maps/api/geocode/json\?components\=administrative_area%3A
                ${state.value}%7Cpostal_code%3A
                ${zip.value}%7Ccountry%3AUS\&key\=${googleApiKey}`,
      })
        .then((res) => {
          if (res.data.status === "OK") {
            setTimeout(() => {
              setSuccess(true);
              setLoading(false);
              setGroupName(name.value);
              setZipCode(zip.value);
              setSicCode(sic.value);
              setState(state.value);
              setGroupEffectiveDate(groupDate.value);
              setSuccess(true);
              setMessage("Your census has been imported.");
              setHasOpportunity(true);
              setValue(3);
            }, 1000);
          } else {
            setLoading(false);
            setError(true);
            setMessage("Zip code entered does not belong to the state that was selected. Go back and try again");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setError(true);
          setMessage("There was an issue with your submission. Please wait a few minutes and try again");
        });
    } else {
      if (validateTaxId) {
        setModalLoading(true);
      }
      setError(false);

      axios({
        method: "get",
        url: `https://maps.googleapis.com/maps/api/geocode/json\?components\=administrative_area%3A
                ${state.value}%7Cpostal_code%3A
                ${zip.value}%7Ccountry%3AUS\&key\=${googleApiKey}`,

        // this layer should act as a the form validations before going through submissions.
        //this little api call above searches with the state/zip entered by the user.
        // If the response is valid then we continue with the submission.
      })
        .then((res) => {
          if (res.data.status === "OK") {
            /* NOTE: Census file is required if requiredLevel is > 1. The update callbacks are optional */
            uploadAllFiles()
              .then((files) => {
                //
                // console.log(broker)
                //
                // console.log(user)
                //
                // console.log(user.crmId)

                let payload = {
                  groupName: name.value,
                  addressLine1: addressLine1.value,
                  addressLine2: addressLine2.value,
                  state: state.value,
                  zip: zip.value,
                  city: city,
                  isBroker: !isBroker.label || isBroker.label === "Yes",
                  adminFee: `${user.adminFee}`,
                  agentId: broker ? broker : user.crmId ? user.crmId.toString() : "12345678",
                  sicCode: sic.value.toString().length === 4 ? sic.value.toString() : "0" + sic.value.toString(),
                  groupEffectiveDate: groupDate.value,
                  notes: brokerName + "\n" + notes,
                };

                // console.log(payload)

                if (isBroker.label === "No") {
                  const broker = {
                    firstName: brokerName,
                    lastName: brokerLast,
                    email: brokerEmail,
                  };
                  payload = { ...payload, broker };
                }
                if (tid.length === 9) {
                  payload = { ...payload, taxId: tid };
                }
                const url = isDev
                  ? "http://localhost:3002/opportunity-manager/v1/crm/createOpportunity"
                  : "/rating/crm/createOpportunity";
                return axios.post(url, { ...payload, docs: files }, restConfig);
              })
              .then((resp) => {
                if (resp.data.isError) {
                  if (validateTaxId) {
                    setTidFail(true);
                  }
                  if (resp.data.errorCode === -10003) {
                    setValidateTaxId(true);
                    webError({
                      text: "User tried to create a new group where the fuzzy logic said a similar group name was found and now they have to enter a taxId",
                      taxId: tid,
                      response: resp.data,
                    });
                  } else {
                    setError(true);
                    setMessage("Something went wrong. Please try again or contact support for help");
                  }
                } else {
                  setAdminFees(resp.data.adminFees);
                  setRestricted(resp.data.productsToRestrict);
                  saveOppToFirebase(resp.data.opportunityId, {
                    crmId: resp.data.crmId,
                  })
                    .then((res) => {
                      setSuccess(true);
                      setLoading(false);
                      setGroupName(name.value);
                      setZipCode(zip.value);
                      setSicCode(sic.value);
                      setState(state.value);
                      setGroupEffectiveDate(groupDate.value);
                      setOpportunityId(resp.data.opportunityId);
                      setSuccess(true);
                      setMessage("Your census has been imported.");
                      setHasOpportunity(true);
                      setValue(3);
                    })
                    .catch((err) => {
                      setLoading(false);
                      setError(true);
                      webError({
                        text: "Something went wrong when trying to save an Opportunity to Firebase within the RFP",
                        err: err.response ? err.response.data : "",
                      });
                      setMessage(
                        "Unable to create quote. Please try again later. If the problem persists, contact support."
                      );
                      console.log(err);
                    });
                }
              })
              .catch((err) => {
                if (validateTaxId) {
                  setTidFail(true);
                }
                setError(true);
                console.log(err);
                /* Do whatever here to let user know the upload failed - probably tell use to contact UGP support for more help */
                setLoading(false);
                webError({
                  text: "Something went wrong when trying to create an RFP.",
                  err: err.response ? err.response.data : "",
                });
                setError(true);
                setMessage("Something went wrong. Please try again. If the problem persists, contact support");
              });
          } else {
            setLoading(false);
            setError(true);
            setMessage("Zip code entered does not belong to the state that was selected. Go back and try again");
          }
        })
        .catch((err) => {
          console.log(err);

          setLoading(false);
          setError(true);
          setMessage("There was an issue submitting your request. Try again in a few minutes.");
        });
    }
  };

  /*NOTE:
    Simple zip code validation that checks to see that the zip code entered is the required length of 5 digits.
    */
  const validateZipCode = () => {
    if (zip.value.length !== 5) {
      setZip({ value: zip.value, error: true });
    }
  };

  const exampleCensus = [
    ["name", "gender", "coverage", "dob"],
    ["John Smith", "M", "EE", "03/02/1957"],
    ["Becky Reed", "F", "EC", "10/20/1988"],
    ["Gilbert Ronahue", "M", "EF", "05/17/1990"],
    ["Rhonda Marty", "F", "EE", "07/01/1983"],
    ["Gina Flex", "F", "ES", "08/20/1977"],
    ["Bill Gilfoyle", "M", "EF", "01/14/1987"],
  ];

  /*NOTE:
    When a user changes the type of census they wish to use, the censusFile variable is reset back to its original state.
    */
  React.useEffect(() => {
    setCensusFile({ file: "", name: "" });
  }, [censusOption]);

  React.useEffect(() => {
    const getDiscounts = async () => {
      const brokerRef = FirebaseDB.collection("discounts").doc(selectedBroker);
      await brokerRef
        .get()
        .then((doc) => {
          setDiscounts({ ...discounts, ...doc.data().discounts });
        })
        .catch((err) => {
          console.log("No discount found.", err);
        });
    };

    if (selectedBroker != undefined) {
      getDiscounts();
    }
  }, [selectedBroker]);

  console.log("this is selected Broker in rfp");
  console.log(selectedBroker);
  console.log("checking for user and roles");
  console.log(user);
  console.log("roles");
  console.log(user.roles);

  //Called during the onKeyDown event for number fields to disallow the characters 'e', '-' and '+'
  const numberTest = (evt) => {
    var charCode = !evt.charCode ? evt.which : evt.charCode;
    if (charCode === 107 || charCode === 109 || charCode === 69) {
      evt.preventDefault();
      return false;
    }
  };

  const selectStyles = {
    menu: (styles) => ({ ...styles, zIndex: "1 !important" }),
  };

  return (
    <div className={classes.paper}>
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container spacing={1} alignItems={"flex-start"} justify={"center"}>
            <Grid item xs={12}>
              <Typography variant={"h4"} style={{ fontWeight: "bold" }}>
                Enter Group Info
              </Typography>
              <Typography>
                In order to quote a new group, we need some information. Please fill out all required fields in order to
                continue.
              </Typography>
            </Grid>
            <CustomTextField
              label={"Group Name"}
              value={name.value}
              inputProps={{ maxLength: 300 }}
              onChange={(e) => {
                if (hasSpecialChars(e.target.value, /[~`!$@%\^&*+=[\]\\;{}|\\":<>\?]/g))
                  return setName({ value: e.target.value, error: true });
                else setName({ value: e.target.value, error: false });
              }}
              error={name.error}
              helperText={name.error === true ? "Please remove any special characters." : ""}
            />
            <Grid item xs={12} container>
              <CustomTextField
                label="Primary Address"
                inputProps={{ maxLength: 250, autoComplete: "new-address1" }}
                value={addressLine1.value}
                onBlur={(e) => setTimeout(() => setPredictions([]), 250)}
                onChange={(e) => {
                  if (hasSpecialChars(e.target.value, /[~`!$@%\^&*+=[\]\\;{}|\\":<>\?]/g))
                    return setAddressLine1({
                      value: e.target.value,
                      error: true,
                    });
                  else setAddressLine1({ value: e.target.value, error: false });
                }}
                error={addressLine1.error}
                helperText={addressLine1.error === true ? "Please remove any special characters." : ""}
              />

              {predictions.map((option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [match.offset, match.offset + match.length])
                );

                return (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.prediction}
                    onClick={(e) => selectPrediction(e, option)}
                    key={JSON.stringify(option)}
                  >
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                          {part.text}
                        </span>
                      ))}

                      <Typography variant="body2" color="textSecondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <CustomTextField
              label="Address Line 2"
              inputProps={{ maxLength: 250, autoComplete: "new-address2" }}
              value={addressLine2.value}
              notRequired
              onChange={(e) => {
                if (hasSpecialChars(e.target.value, /[~`!$@%\^&*+=[\]\\;{}|\\":<>\?]/g))
                  return setAddressLine2({
                    value: e.target.value,
                    error: true,
                  });
                else setAddressLine2({ value: e.target.value, error: false });
              }}
              error={addressLine2.error}
              helperText={addressLine2.error === true ? "Please remove any special characters." : ""}
            />
            <CustomTextField
              onChange={(e) => setZip({ value: e.target.value, error: false })}
              onKeyDown={numberTest}
              value={zip.value}
              onBlur={validateZipCode}
              type="number"
              label="Zip Code"
              error={zip.error}
              inputProps={{ autoComplete: "new-zip", maxLength: 5 }}
              helperText={zip.error === true ? "Zip code must be 5 digits long" : ""}
            />

            <CustomTextField
              label="City"
              required
              inputProps={{ maxLength: 100, autoComplete: "new-address2" }}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              error={hasSpecialChars(city, /[~`!#$%\^&*+=[\]\\';,/{}|\\":<>\?]|[0-9]/g)}
              helperText={
                hasSpecialChars(city, /[~`!#$%\^&*+=[\]\\';,/{}|\\":<>\?]|[0-9]/g)
                  ? "No Special Characters or Numbers Allowed"
                  : ""
              }
            />
            <Grid item xs={12} style={{ zIndex: 19 }}>
              <Typography>State</Typography>
              <Select
                placeholder="State"
                value={state}
                onChange={(e) => setState(e)}
                maxMenuHeight={150}
                options={states}
                styles={selectStyles}
              />
            </Grid>
            <Grid item xs={12} style={{ zIndex: 18 }}>
              <Typography>Group Effective Date</Typography>
              <Select
                placeholder="Group Effective Date"
                value={groupDate}
                onChange={(e) => setGroupDate(e)}
                maxMenuHeight={150}
                options={genDates().map((x) => {
                  return { label: x, value: x };
                })}
                styles={selectStyles}
              />
            </Grid>
            <Grid item xs={12} style={{ zIndex: 17 }}>
              <Typography>SIC Code</Typography>
              <Select
                placeholder="SIC Code"
                value={sic}
                onChange={(e) => setSic(e)}
                maxMenuHeight={150}
                options={SICs}
                styles={selectStyles}
              />
            </Grid>

            {canabisdialogue && (
              <Dialog open={canabisdialogue}>
                <DialogActions className={classes.dialog}>
                  <Grid container direction="column" justify="space-between" alignItems="center">
                    <FormLabel component="legend">Before you continue</FormLabel>
                    <br />
                    <FormGroup>
                      <br />
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              //checked={sendtoOptienhance}
                              color="primary"
                              onChange={handleChange}
                              name="canabisChecker"
                            />
                          }
                          label="Is this group involved in operations, dispensing, or growing of cannabis?"
                        />
                        {/* <Tooltip title={""} placement={"top"}>
                          <IconButton>
                            <InfoIcon style={{ color: "#0C2659" }} />
                          </IconButton>
                        </Tooltip> */}
                      </div>
                    </FormGroup>
                  </Grid>
                </DialogActions>
                <DialogActions>
                  <Button
                    onClick={(e) => {
                      setCanabisDialogue(false);
                      // setRxDialog(true);
                    }}
                    color="primary"
                    style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                  >
                    Continue
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {user.roles && user.roles.indexOf("broker") !== -1 ? (
              <>
                <Grid item xs={12} style={{ zIndex: 16 }}>
                  <Typography>I am the broker/agent on this quote</Typography>
                  <Tooltip
                    title={"This is the name that will appear on the quote being supplied to the group."}
                    placement={"top"}
                  >
                    <Select
                      placeholder="Broker/Agent on this quote?"
                      value={isBroker}
                      onChange={(e) => setIsBroker(e)}
                      maxMenuHeight={150}
                      options={["Yes", "No"].map((x) => {
                        return { label: x, value: x };
                      })}
                      styles={selectStyles}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={isBroker.label === "No"}>
                    <Grid container justify={"center"} spacing={2}>
                      <CustomTextField
                        label="Broker's First Name"
                        value={brokerName}
                        onChange={(e) => setBrokerName(e.target.value)}
                      />
                      <CustomTextField
                        label="Broker's Last Name"
                        value={brokerLast}
                        onChange={(e) => setBrokerLast(e.target.value)}
                      />
                      <CustomTextField
                        label="Broker's Email"
                        value={brokerEmail}
                        onChange={(e) => setBrokerEmail(e.target.value)}
                      />
                    </Grid>
                  </Collapse>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} style={{ zIndex: 14 }}>
                  <Typography>Select a Broker (Start typing your broker's name to get options)</Typography>
                  <OptionsSelect
                    value={broker}
                    onValueChanged={(value) => {
                      setBroker(value);
                      setSelectedBroker(value);
                    }}
                    options={brokerOptions}
                    loadOptions={search(FirebaseDB.collection("crmBrokers"), "brokerName", setBrokerOptions)}
                  />
                  {/*<AsyncSelect placeholder='Broker' value={broker} onChange={(e) => setBroker(e)} maxMenuHeight={150} cacheOptions defaultOptions loadOptions={getBrokerList} styles={selectStyles}/>*/}
                </Grid>
                <Grid item xs={12} style={{ zIndex: 13 }}>
                  <Typography>Should account management complete this quote?</Typography>
                  <Tooltip
                    title={"A notification will be sent to account management to complete this quote."}
                    placement={"top"}
                  >
                    <Select
                      value={notifySalesSupportToComplete}
                      onChange={(e) => setNotify(e)}
                      maxMenuHeight={150}
                      options={["Yes", "No"].map((x) => {
                        return { label: x, value: x };
                      })}
                      styles={selectStyles}
                    />
                  </Tooltip>
                  <Divider />
                </Grid>
              </>
            )}
            {requiredLevel !== 1 && (
              <>
                <Grid item xs={12} style={{ zIndex: 12 }}>
                  <Typography>Census File Type</Typography>
                  <Tooltip
                    title={
                      "Optimed's census file type allows you to upload a file in our format and not have to reenter all of your census data."
                    }
                    placement={"top"}
                  >
                    <Select
                      value={censusOption}
                      placeholder={"Census File Type"}
                      onChange={(e) => setCensusOption(e)}
                      maxMenuHeight={150}
                      options={censusOptions.map((x) => {
                        return { label: x, value: x };
                      })}
                      styles={selectStyles}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={censusOptions.indexOf(censusOption.label) === 1}>
                    <CustomTextField
                      label={"Upload member level census. Include Name, DOB, Gender and Coverage"}
                      type={"file"}
                      onChange={(e) => fileUpload(e, setCensusFile)}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={censusOptions.indexOf(censusOption.label) === 0}>
                    <Grid item xs={12}>
                      {censusParsed && (
                        <Typography align={"center"} color={"primary"}>
                          Census is Valid <CheckIcon />
                        </Typography>
                      )}
                      {!error && (
                        <CSVReader
                          ref={buttonRef}
                          onFileLoad={handleReadCSV}
                          onError={handleOnError}
                          noClick
                          noDrag
                          disabled={censusParsed}
                          config={{ header: true }}
                        >
                          {({ file }) => (
                            <Button
                              disabled={censusParsed}
                              onClick={openDialog}
                              style={{
                                backgroundColor: "#0C2659",
                                color: "#FFFFFF",
                              }}
                              endIcon={censusParsed ? <CheckIcon /> : ""}
                            >
                              Click here to select a file in OptiMed format
                            </Button>
                          )}
                        </CSVReader>
                      )}
                      <Grid item xs={12}>
                        <CSVLink filename={"ExampleCensus.csv"} className={classes.link} data={exampleCensus}>
                          <Button
                            size="small"
                            disabled={censusParsed}
                            type={"button"}
                            variant={"text"}
                            fullWidth
                            color={"#0C2659"}
                            style={{
                              backgroundColor: "#0C2659",
                              color: "#FFFFFF",
                            }}
                          >
                            Download example census format
                          </Button>
                        </CSVLink>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </>
            )}
            <Grid item xs={12} style={{ zIndex: 11 }}>
              I would like to add additional attachments
              <Checkbox color="primary" checked={addFiles} onChange={() => setAddFiles(!addFiles)} />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={addFiles}>
                <Grid container justify={"center"} spacing={2}>
                  <CustomTextField label="Additional File 1" type="file" onChange={(e) => fileUpload(e, setAddFile1)} />
                  <CustomTextField label="Additional File 2" type="file" onChange={(e) => fileUpload(e, setAddFile2)} />
                  <CustomTextField label="Additional File 3" type="file" onChange={(e) => fileUpload(e, setAddFile3)} />
                  <CustomTextField label="Additional File 4" type="file" onChange={(e) => fileUpload(e, setAddFile4)} />
                </Grid>
              </Collapse>
            </Grid>
            {isEmployee && (
              <Grid item xs={12}>
                <TextField
                  label="Additional Notes"
                  value={notes}
                  multiline
                  rows={3}
                  inputProps={{ maxLength: 1000 }}
                  onChange={(e) => setNotes(e.target.value)}
                  fullWidth
                  variant={"outlined"}
                  color={"primary"}
                />
              </Grid>
            )}
            <Grid item xs={10}>
              <Button type="submit" disabled={!isValid()} style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      {validateTaxId && (
        <ValidateTaxId
          modal={validateTaxId}
          loading={modalLoading}
          closeModal={() => setValidateTaxId(false)}
          setTid={setTid}
          tid={tid}
          handleSubmit={handleSubmit}
        />
      )}
      {tidFail && (
        <TidFailure
          modal={tidFail}
          closeModal={() => {
            setTidFail(false);
            setValidateTaxId(false);
          }}
        />
      )}
    </div>
  );
}
