import React from 'react'
import {CSVReader} from 'react-papaparse'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {CSVLink} from "react-csv";
import {RaterContext} from "../../RaterV3";
import _ from "lodash";
import dateformat from "dateformat";

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none'
    },
    grid: {
        marginTop: theme.spacing(2)
    }
}))

export default function CensusUpload() {
    const {setCensus, validateCensus, setError, setMessage, setLoading, setSuccess} = React.useContext(RaterContext)
    const classes = useStyles()

    const data = [
        ["name", "gender", "coverage", 'dob'],
        ["John Smith", "M", "EE", "03/02/1957"],
        ["Becky Reed", "F", "ES", "10/20/1988"],
        ["Gilbert Ronahue", "M", "EF", "05/17/1990"],
        ["Rhonda Marty", "F", "EE", "07/01/1983"],
        ["Gina Flex", "F", "ES", "08/20/1977"],
        ["Bill Gilfoyle", "M", "EF", "01/14/1987"],
    ]
    const buttonRef = React.createRef()

    console.log("Running Census")

    const handleReadCSV = (data) => {
        setLoading(true)
        let employees = data.map(row => {return row.data})

        //Maps all keys to lower case for case insensitivity in csv file.
        employees = _.map(employees, x => {
            return _.mapKeys(x, (value, key) => {
                return (key.toLowerCase())
            })
        })

        //Function that maps the date of birth to the proper format for /products endpoint.
        employees = employees.map(employee => {
            let dob = dateformat(employee.dob, 'mm/dd/yyyy', true)
            let gender = employee.gender ? employee.gender.charAt(0): undefined
            return {name: employee.name, dob, coverage: employee.coverage, gender}
        })
        employees = _.filter(employees, x => x.name && x.dob && x.coverage && x.gender)
        validateCensus(employees)
            .then(res => {
                setSuccess(true)
                setMessage("Census uploaded successfully. Verify your census is completed and continue.")
                setLoading(false)
                setCensus(employees)
            })
            .catch(err => {
                setLoading(false)
                setError(true)
                setMessage(typeof err === 'string' ? err:"Invalid census format. You must either fill out census manually or upload a file with the proper format.")
            })
    }

    const handleOnError = (err) => {
        console.log(err)
    }
    const openDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    return (
        <>
                    <Grid container item xs={12} spacing={2} justify='center' className={classes.grid}>
                        <Grid item xs={12}>
                            <CSVLink filename={'ExampleCensus.csv'} className={classes.link} data={data}><Button fullWidth variant={'outlined'} color={'primary'} style={{backgroundColor: '#0C2659', color:'#FFFFFF'}}>Download optimed census template</Button></CSVLink>
                        </Grid>
                        <Grid item xs={12}>
                                <CSVReader
                                    ref={buttonRef}
                                    onFileLoad={handleReadCSV}
                                    onError={handleOnError}
                                    noClick
                                    noDrag
                                    config={{header:true}}
                                >
                                    {({file}) => (
                                                <Button fullWidth variant={'outlined'} color='primary' style={{backgroundColor: '#0C2659',color :'#FFFFFF'}} onClick={openDialog}>Upload New Optimed Census Template</Button>
                                    )}
                                </CSVReader>
                            </Grid>
                    </Grid>
        </>
    )
}
